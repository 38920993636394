$bp-2-cols: 34em;
$bp-3-cols: 60em;

@mixin breakpoint($size) {
  @media all and (min-width: $size) {
    @content;
  }
}

body {
  background: white;
  color: black;
  font-size: 100%;
  font-family: sans-serif;
}

svg {
  display: block;
}

.logo {
  width: 140px;
}

.comic {
  overflow: hidden;
}

.frame {
  position: relative;
  padding: 36px;
  float: left;
  width: 100%;
  @include breakpoint($bp-2-cols) {
    max-width: 50%;
  }
  @include breakpoint($bp-3-cols) {
    max-width: 33%;
  }
}

.narration {
  position: absolute;
  font-size: 80%;
  @include breakpoint($bp-3-cols) {
    font-size: 100%;
  }
  line-height: 1.5;
  span {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    background: black;
    color: white;
    padding: 3px;
    box-shadow: 5px 0 0 black;
    @include breakpoint($bp-3-cols) {
      padding: 5px;
    }
  }
  a {
    color: white;
  }
}
[dir="rtl"] {
  .narration {
    span {
      display: inline-block;
      box-shadow: none;
    }
  }
}

.title {
  font-size: 24px;
  font-weight: 900;
}

.evil {
  color: black !important;
  background: #FF7E79 !important;
  box-shadow: 5px 0 0 #FF7E79 !important;
}

.reverse {
  color: black !important;
  background: white !important;
  box-shadow: 5px 0 0 white !important;
}

.bubble {
  position: absolute;
  display: inline-block;
  padding: 5px 8px;
  background: white;
  border: 3px solid black;
  border-radius: 5px;
  font-size: 80%;
  @include breakpoint($bp-3-cols) {
    font-size: 100%;
  }
  p {
    margin: 0;
  }
  .arrow {
    width: 40px;
    position: absolute;
  }
  .down {
    bottom: -38px;
  }
  .up {
    top: -38px;
  }
}

.link-to-dnsimple {
  &:hover {
    .dnsimple-logo-bg {
      transition: fill .4s ease;
      fill: #ff7e79;
    }
    .dnsimple-logo-text {
      fill: white;
    }
  }
}

.comic-red {
  color: #ff7e79;
}

.start-reading-cta {
  &:hover {
    #certificat-face {
      transition: transform ease-in-out .3s;
      transform: translate(0, -20px);
    }
  }
}

#certificat-face {
  transition: transform ease-in-out .3s;
  transform: translate(0, 0px);
}

.btn-cta {
  background: #ff7e79;
  color: white;
}

@mixin transform($transformation) {
  -webkit-transform: $transformation;
  -moz-transform: $transformation;
  -ms-transform: $transformation;
  -o-transform: $transformation;
  transform: $transformation;
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -ms-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(loopy-eyes) {
  0% {
    @include transform(translate(8px, 8px));
  }
  25% {
    @include transform(translate(-8px, 8px));
  }
  50% {
    @include transform(translate(-8px, -8px));
  }
  75% {
    @include transform(translate(8px, -8px));
  }
  100% {
    @include transform(translate(8px, 8px));
  }
}

.loopy-eyes {
  @include animation(loopy-eyes 1s infinite linear);
}

@include keyframes(crying) {
  0% {
    @include transform(translate(-1px, -1px));
  }
   100% {
    @include transform(translate(0, 1px));
  }
}

.crying {
  @include animation(crying .2s infinite linear);
}

// caption positions
.top-0   { top: 0;    }
.top-10  { top: 10%;  }
.top-20  { top: 20%;  }
.top-30  { top: 30%;  }
.top-40  { top: 40%;  }
.top-50  { top: 50%;  }
.top-60  { top: 60%;  }
.top-70  { top: 70%;  }
.top-80  { top: 80%;  }
.top-90  { top: 90%;  }
.top-100 { top: 100%; }

.bottom-0   { bottom: 0;    }
.bottom-10  { bottom: 10%;  }
.bottom-20  { bottom: 20%;  }
.bottom-30  { bottom: 30%;  }
.bottom-40  { bottom: 40%;  }
.bottom-50  { bottom: 50%;  }
.bottom-60  { bottom: 60%;  }
.bottom-70  { bottom: 70%;  }
.bottom-80  { bottom: 80%;  }
.bottom-90  { bottom: 90%;  }
.bottom-100 { bottom: 100%; }

.left-0   { left: 0;    }
.left-10  { left: 10%;  }
.left-20  { left: 20%;  }
.left-30  { left: 30%;  }
.left-40  { left: 40%;  }
.left-50  { left: 50%;  }
.left-60  { left: 60%;  }
.left-70  { left: 70%;  }
.left-80  { left: 80%;  }
.left-90  { left: 90%;  }
.left-100 { left: 100%; }

.right-0   { right: 0;    }
.right-10  { right: 10%;  }
.right-20  { right: 20%;  }
.right-30  { right: 30%;  }
.right-40  { right: 40%;  }
.right-50  { right: 50%;  }
.right-60  { right: 60%;  }
.right-70  { right: 70%;  }
.right-80  { right: 80%;  }
.right-90  { right: 90%;  }
.right-100 { right: 100%; }
